/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "../components/sections/header"
import Footer from "../components/sections/footer"
import "../scss/layout.scss"
import SocialMedia from "./sections/SocialMedia"
import SideMenu from "./sections/sidemenu"
import CookieNotice from "./elements/CookieNotice"

const Layout = ({ children, sidemenu, contentObjects }) => {

    let transparent = false;

    contentObjects.forEach(element => {
        if(element.id === 'start_header') transparent = true;

    });

    return (
        <>
            <Header
                transparent={transparent}
            />
            {sidemenu === "true" ? <SideMenu
                data={contentObjects}
            /> : null}
            <main
                className={sidemenu === "true" ? "sidemenu" : null}
            >{children}</main>
            <SocialMedia
                transparent
            />
            <Footer />
            <CookieNotice />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
