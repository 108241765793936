import React, { useRef, useState } from "react"
import * as styles from "./accordion.module.scss"
import Arrow from "../../../images/Icons/arrow_down.svg"
import parse from 'html-react-parser';
import Button from "../Button";

const Accordion = (props) => {

    const [open, setOpen] = useState(props.open || false);

    return (
        <div className={styles.accordion}>
            <div
                className={styles.header}
                onClick={() => setOpen(!open)}
            >
                <Arrow
                    className={styles.icon}
                    style={{
                        transform: `rotate(${open ? 180 : 0}deg)`,
                    }}

                />
                <h3>
                    {parse(props.headline)}
                </h3>
            </div>

            {open ?
                <div className={styles.content}>
                    {parse(props.text)}

                    {props.button_link !== undefined && props.button_link !== '' ? 
                        <Button type={"line"} destination={props.button_link} target={'_blank'} title={props.button_text} />
                    : null}
                </div>
                : null
            }
        </div>
    );


}

export default Accordion
