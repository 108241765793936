/**
 * Page elements like Text, Headline, Slider, etc.
 */
import React from "react"
import Button from "./Button";
import Row from "./Row";
import Headline from "./Headline";
import StartHero from "./StartHero";
import Hero from "./Hero";
import TextContainer from "./TextContainer";
import TextSection from "./TextSection";
import Slider from "./Slider";
import Image from "./Image";
import AccordionContainer from "./Accordion";
import Timeline from "./Timeline";
import TimelineList from "./TimelineList";
import References from "./References";
import Text from "./Text";
import ContactForm from "./ContactForm";
import TeamSlider from "./TeamSlider";
//  import Hero from "./Hero";
//  import Spacer from "./Spacer";
//  import Cascade from "./Cascade";
//  import Subheadline from "./Subheadline";
//  import SubheadlineContainer from "./SubheadlineContainer";
//  import GalleryContainer from "./GalleryContainer";
//  import Map from "./Map";
//  import ContactForm from "./ContactForm";
//  import Slider from "./Slider";
//  import Video from "./Video";
//  import Team from "./Team";
//  import BackgroundImage from "./BackgroundImage";
//  import SvgHeadline from "./SvgHeadline";



export const renderElements = (content_raw) => {

    let content = content_raw;

    if (typeof content_raw !== 'object') {

        try {
            content = JSON.parse(content_raw);
        } catch {
            return 'NO OBJECT FOUND'
        }

    }

    console.log("content", content)

    if (content === null) {
        return 'NO OBJECT FOUND'
    }

    return content.map(element => {

        switch (element.id) {
            case 'row':
                return <Row
                    {...element}
                />

            case 'button':
                return <Button
                    {...element}
                />

            case 'text':
                return <Text
                    {...element}
                />

            case 'single_image':
                return <Image
                    {...element}
                />

            case 'headline':
                return <Headline
                    {...element}
                />

            case 'start_header':
                return <StartHero
                    {...element}
                />

            case 'header':
                return <Hero
                    {...element}
                />

            case 'text-image-section':
                return <TextSection
                    {...element}
                />

            case 'slider':
                return <Slider
                    {...element}
                />

            case 'cascade':
                return <AccordionContainer
                    {...element}
                />

            case 'timeline':
                return <Timeline
                    {...element}
                />

            case 'timeline-list':
                return <TimelineList
                    {...element}
                />

            case 'references':
                return <References
                    {...element}
                />

            case 'contact-form':
                return <ContactForm
                    {...element}
                />

            case 'team':
                return <TeamSlider
                    {...element}
                />

            default:
                return <div></div>
        }

    });

}



/*

let default_element =
{
    element: 'headline',
    data: {
        headline: 'Text',
        subheadline: 'Subtext',
        subheadline: 'Subtext',
        id: 'this id',
    }
}

default_element =
    {
        element: 'container',
        data: {
            props: ['fluid']
        },
        children: [
            {
                element: 'column',
                data: {
                    width: '6',
                    props: ['same-height']
                },
                children: [
                    {
                        element: 'headline',
                        data: {
                            headline: 'Text',
                            subheadline: 'Subtext',
                            subheadline: 'Subtext',
                            id: 'this id',
                        }
                    },
                    {
                        element: 'button',
                        data: {
                            type: 'outlined',
                            text: 'Text',
                            link: 'https://...',
                            target: '',
                            id: 'this id',
                        }
                    },
                    {
                        element: 'accordion',
                        data: {
                            type: 'outlined',
                            text: 'Text',
                            link: 'https://...',
                            target: '',
                            id: 'this id',
                        },
                        children: [
                            {

                            }
                        ]
                    }
                ]
            }
        ]
    }

    React Drag and Drop Editor -->

    */