import React, { useEffect, useState } from "react"

import * as styles from "../../scss/sidemenu.module.scss"

import Logo from "../../images/logo_dark.svg"
import MenuIcon from "../../images/Icons/icon_menu.svg"
import SearchIcon from "../../images/Icons/search.svg"





const SideMenu = (props) => {

    const [currentField, setCurrentField] = useState(null);

    useEffect(() => {

        console.log("PAGE PROPS", props.data);

        // subscribe event
        window.addEventListener("scroll", handleScroll);
        return () => {
            // unsubscribe event
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);


    const handleScroll = (e) => {

        const currentPosition = window.scrollY;

        const elements = Array.from(document.getElementsByClassName('hasSidemenuAnchor'));

        elements.sort((a, b) => {
            return a.offsetTop > b.offsetTop
        })

        console.log(elements);
        elements.forEach((element, key) => {

            const top = element.offsetTop;
            let bottom = document.body.scrollHeight;
            if (elements[key + 1] !== undefined) {
                bottom = elements[key + 1].offsetTop - 1;
            }

            if (currentPosition > (top - 60) && currentPosition < bottom) {
                setCurrentField(element.getAttribute('sidemenu_id'))
            }

        });

    }

    const performScroll = (finalY) => {

        const duration = 500;

        let steps = duration / 10
        let start = window.scrollY;
        let perStep = (finalY - start) / steps;

        for (let i = 0; i < steps; i++) {

            setTimeout(() => {
                window.scrollTo({
                    left: 0,
                    top: start + perStep * i
                })
            }, 10 * i);

        }

        


    }

    const scrollToElement = (id) => {

        const elements = Array.from(document.getElementsByClassName('hasSidemenuAnchor'));

        let position = 0;
        elements.forEach(element => {
            if (element.getAttribute('sidemenu_id') === id) {
                position = element.offsetTop;
            }
        });

        performScroll(position)
        setTimeout(() => {setCurrentField(id)}, 500 + 1000)

    }

    let key = -1;

    return (
        <div className={`${styles.container} `}>
            <ul>
                {
                    props.data.map((field) => {
                        if (field.sidemenu_title === undefined || field.sidemenu_title === null || field.sidemenu_id === undefined || field.sidemenu_id === null) return;
                        key = key + 1;
                        return <li
                            className={((currentField === field.sidemenu_id || (currentField === null && key === 0)) ? `${styles.current}` : null)}
                            onClick={() => scrollToElement(field.sidemenu_id)}
                        >
                            {field.sidemenu_title}
                        </li>;

                    })
                }

            </ul>
        </div>
    )



}

export default SideMenu


