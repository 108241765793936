import React, { useRef } from "react"
import * as styles from "./teamslider.module.scss"
import Arrow from "../../../images/Icons/arrow_right.svg"
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TeamSlider = (props) => {

    const slider = useRef(null);
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const renderSlide = () => {

        return props.teammembers.map(teammember => {

            return <div className={styles.slide}>
                <div className={styles.img} style={{backgroundImage: `url(${teammember.image_url})`}} />
                <div className={styles.label}>{teammember.title}</div>
                <div className={styles.position}>{teammember.position}</div>
            </div>

        });

    }

    return (
        <div className={styles.container}>
            <SlickSlider
                ref={slider}
                {...settings}
            >
                {renderSlide()}
            </SlickSlider>
            <Arrow
                className={styles.arrow}
                onClick={() => {
                    slider.current.slickNext();
                }}
            />
        </div>
    );


}

export default TeamSlider
