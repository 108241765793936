import React from "react"

const Image = (props) => {

    return (
        <img src={props.url} 
            style={{width: '100%'}}
        />
    );

}

export default Image
