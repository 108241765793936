import React, { useRef, useState } from "react"
import parse from 'html-react-parser';

const Text = (props) => {

    return (
        <div style={props.style}>
            {parse(props.text)}
        </div>
    );


}

export default Text
