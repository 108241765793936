import React from "react"
import * as styles from "./accordion.module.scss"
import Accordion from "./Accordion";

const AccordionContainer = (props) => {

    const renderAccordions = () => {

        return props.children.map(settings => {
            return <Accordion {...settings} />
        });

    }

    return (
        <div className={styles.container}>
            {renderAccordions()}
        </div>
    );


}

export default AccordionContainer