import React from "react"
import * as styles from "./textsection.module.scss"

import Button from "../Button"
import Headline from "../Headline"
import parse from 'html-react-parser';

const TextSection = (props) => {

    return (
        <div className={"container " + `${styles.wrapper} ` + (props.style.alignment === 'image_right' ? `${styles.right} ` : null)} style={{ ...props.additionalStyles }}>
            {props.style.alignment === 'image_left' ?
                <div className={styles.imageContainer}>
                    <img src={props.image} />
                </div>
                : null
            }
            <div className={styles.textContainer} style={{ paddingTop: props.offset || 0 }}>
                <Headline
                    subheadline={props.subheadline}
                    headline={props.headline}
                    stroke
                    left
                />

                <p>
                    {parse(props.text)}
                </p>
                {
                    props.link !== undefined && props.link !== null && props.link !== "" && props.linkText !== undefined && props.linkText !== null ?

                        <Button
                            type="line"
                            title={props.linkText}
                            destination={props.link}
                        />
                        :
                        null
                }
            </div>
            {props.style.alignment === 'image_right' ?
                <div className={styles.imageContainer}>
                    <img src={props.image} />
                </div>
                : null
            }
        </div >
    )
}

export default TextSection
