import { API_BASE } from '../../../spectory-config';

const axios = require('axios');

export const sendContactForm = async (name, email, message) => {

    console.log("CONTACT", `${API_BASE}/contact`);
    const res = await axios.post(`${API_BASE}/contact`, {
        name: name,
        email: email,
        message: message
    })

    return res;

}