import React, { useRef, useState } from "react"
import * as styles from "./timeline.module.scss"
import parse from 'html-react-parser';

const Timeline = (props) => {

    return (
        <table
        className={styles.timeline}>
            {props.children.map(element => {

                return <tr>
                    <td><strong>{element.year}</strong></td>
                    <td>{parse(element.text)}</td>
                </tr>
            })}
        </table>

    );


}

export default Timeline
