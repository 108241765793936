import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { renderElements } from "../components/elements"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    wpPage(slug: { eq: $slug }) {
        id
      slug
      title
      renderContent
      sideMenu
    }
  }
`
const SecondPage = props => {

    console.log("sideMenu", props.data.wpPage.sideMenu);

    return (
        <Layout
            sidemenu={props.data.wpPage.sideMenu}
            contentObjects={JSON.parse(props.data.wpPage.renderContent)}
        >
            <SEO title={props.data.wpPage.title} />
            {renderElements(props.data.wpPage.renderContent)}
        </Layout>
    )
}

export default SecondPage