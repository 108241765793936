import React from "react"
import * as styles from "./headline.module.scss"
import parse from 'html-react-parser';

const Headline = (props) => {

    return (
        <div>
            {
                props.subheadline ?
                    <div className={styles.subTitle}>{props.subheadline}</div>
                    : null
            }
            <h1 className={`${styles.headline} ` + (props.stroke ? `${styles.stroke} ` + (props.right ? `${styles.right} ` : `${styles.left} `) + (props.edge ? `${styles.toEdge} ` : null) : null)}>{parse(props.headline || props.text)}</h1>
        </div>
    )
    
}

export default Headline
