import React, { useRef, useState } from "react"
import * as styles from "./timelinelist.module.scss"
import parse from 'html-react-parser';
import Timeline from "../Timeline";

const TimelineList = (props) => {

    if (props.type === 'table') {
        return <Timeline {...props} />
    }

    return (
        <div className={styles.wrapper}>
            {
                props.children.map(element => {
                    return <TimelineListElement {...element} />
                })
            }
        </div>
    );


}

const TimelineListElement = (props) => {

    return (
        <div className={styles.container}>
            <h3>{parse(props.year)}</h3>
            <div>{parse(props.text)}</div>
            <div className={styles.line}></div>
        </div>
    )

}

export default TimelineList
