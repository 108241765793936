import React, { useRef, useState } from "react"
import Slider from "../Slider";
import TextSection from "../TextSection";

const References = (props) => {

    if (props.type !== undefined && props.type === 'slider') {

        // REARRANGE FORMAT

        let formatted = {
            children : props.references
        }

        formatted.children.forEach((element, key) => {
            formatted.children[key].headline = element.title;
        })

        return <Slider 
            {...formatted}
        />

        return <div></div>;

    } else {

        return (
            props.references.map((reference, key) => {

                return <TextSection
                    image={reference.image_url}
                    headline={reference.title || ""}
                    subheadline={reference.city || ''}
                    text={reference.content || ""}
                    style={{ alignment: key % 2 ? 'image_right' : 'image_left' }}
                    additionalStyles={{ paddingTop: 50, paddingBottom: 50 }}
                />

            })
        )

    }

}

export default References
